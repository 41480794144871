export const getConfig = (key) => {
  const base = {
    BASE_URL: 'http://localhost:3000',
    REACT_APP_FIREBASE_PUB_KEY: 'AIzaSyCqZdu33d2XTd_YxEPK0rcychamFm9QXA8',
    REACT_APP_FIREBASE_AUTH_DOMAIN: 'dealsend.firebaseapp.com',
    REACT_APP_FIREBASE_PROJECT_ID: 'dealsend',
    REACT_APP_FIREBASE_STORAGE_BUCKET: 'dealsend.appspot.com',
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '765293680774',
    REACT_APP_FIREBASE_APP_ID: '1:765293680774:web:5882d6e4c1cdd0eb9b9907',
    LOCAL_API_ENDPOINT: 'http://localhost:5001/dealsend/us-central1/api_dev_v2',
    ENV: 'development',
  };

  const development = {
    ...base,
  };

  const feature = {
    ...development,
    BASE_URL: 'http://localhost:3000',
    LOCAL_API_ENDPOINT: 'http://localhost:5001/dealsend/us-central1/api_dev_v2',
    ENV: 'feature',
  };

  const qa = {
    ...development,
    BASE_URL: 'https://qa-dealsend.web.app',
    LOCAL_API_ENDPOINT: 'https://us-central1-dealsend.cloudfunctions.net/api_qa_v2',
    ENV: 'qa',
  };

  const production = {
    ...qa,
    BASE_URL: 'https://app.dealsend.io',
    LOCAL_API_ENDPOINT: 'https://us-central1-dealsend.cloudfunctions.net/api_prod_v2',
    ENV: 'production',
  };

  const configSettings = {
    development,
    feature,
    qa,
    production,
  };

  const env = process.env.REACT_APP_ENV || 'development';

  const config = configSettings[env] || configSettings.development;

  if (!key) {
    return config;
  }

  return config[key];
};

export default {
  getConfig,
};
